.container {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	min-width: 100vw;
	max-width: 100vw;
	min-height: 100vh;
	max-height: 100vh;
	flex-direction: column;
}

.imageContainer {
	height: 200px;
}

.modalTitle, .modalMessage {
	text-align: center;
}

.modalTitle {
	font-size: 24px;
}

.modalMessage {
	font-size: 16px;
}

.App {
	text-align: center;
}

.Applogo {
	max-height: 200px;
	pointer-events: none;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}