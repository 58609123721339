.container {
  display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: 100vw;
	max-width: 100vw;
	min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.modalTitle, .modalMessage  {
  text-align: center;
}

.modalTitle {
  font-size: 24px;
}

.modalMessage {
  font-size: 16px;
}

.submitButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.errorInput {
  font-size: 14px;
  margin-left: 4px;
  color: #e32909
}