.container {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	min-width: 100vw;
	max-width: 100vw;
	min-height: 100vh;
	max-height: 100vh;
	flex-direction: column;
}

.modalTitle, .modalMessage  {
  text-align: center;
}

.modalTitle {
  font-size: 24px;
}

.modalMessage {
  font-size: 16px;
}